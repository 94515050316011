/* eslint-disable no-case-declarations */
import { STORE_SHOWCASE, GET_SHOWCASE } from 'redux/actions';

const initialState = { showcases: new Map() };

export default (state = initialState, action) => {
  switch (action.type) {
    case STORE_SHOWCASE:
      const incomingShowcase = action.payload;
      if (state.showcases.has(incomingShowcase.id)) {
        state.showcases.delete();
      }
      state.showcases.set(incomingShowcase.id, incomingShowcase);
      return {
        showcases: state.showcases
      };

    case GET_SHOWCASE:
      return state.showcases.get(action.payload);

    default:
      return state;
  }
};
